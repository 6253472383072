import axios from 'axios'
import { AuthActions, AuthGetters, AuthMutations, ProjectsActions, ProjectsPath, TrainingsActions, TrainingsPath, UsersActions, UsersPath } from './definitions'

const authStore = {
    namespaced: true,
    state: {
      token: localStorage.getItem('user-token') || '',
      status: '',
      data: '',
      role: ''
    },

    getters: {
      [AuthGetters.IS_AUTHENTICATED]: state => !!state.token,
      [AuthGetters.STATUS]: state => state.status,
      [AuthGetters.USERID]: state => state.data ? state.data.id : 0,
      [AuthGetters.CAN_BE_ADMIN]: state => state.data.roles.includes('admin'),
      [AuthGetters.IS_ADMIN]: state => (state.role === 'admin') && state.data.roles.includes('admin'),
      [AuthGetters.IS_USER]: state => (state.role === 'user')
    },

    actions: {
      [AuthActions.REFRESH]: ({ state, commit, dispatch }) => {
        return new Promise((resolve) => {
          if(state.token !== '') {
            commit(AuthMutations.SUCCESS, state.token)
            dispatch(AuthActions.REFRESH_DATA)
            .then(() => {
              if (state.role == '') {
                if (state.data.roles.includes('admin')) {
                  commit(AuthMutations.SET_ROLE, 'admin')
                } else {
                  commit(AuthMutations.SET_ROLE, 'user')
                }
              }
              resolve();
            })
          }
        })
      },
      [AuthActions.REQUEST]: ({ state, dispatch, commit }, user) => {
        return new Promise((resolve, reject) => {
          commit(AuthMutations.REQUEST);
          axios({url: '/auth/login', data: user, method: 'POST' })
            .then(resp => {
              const token = resp.data.data.token
              commit(AuthMutations.SUCCESS, token)
              dispatch(AuthActions.REFRESH_DATA)
              .then(() => {
                if (state.role == '') {
                  if (state.data.roles.includes('admin')) {
                    commit(AuthMutations.SET_ROLE, 'admin')
                  } else {
                    commit(AuthMutations.SET_ROLE, 'user')
                  }
                }
                resolve(resp)
              })
            })
          .catch(err => {
            commit(AuthMutations.ERROR, err)
            localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
            reject(err)
          })
        })
      },
      [AuthActions.REGISTER]: (_, payload) => new Promise((resolve, reject) => {
        axios({url: '/auth/register', data: payload, method: 'POST' })
        .then(() => resolve())
        .catch(err => {
          reject(err)
        })
      }),

      [AuthActions.LOGOUT]: ({ dispatch, commit }) => {
          return new Promise((resolve) => {
              commit(AuthMutations.LOGOUT)
              dispatch(ProjectsPath + '/' + ProjectsActions.RESET, null, { root: true });
              dispatch(TrainingsPath + '/' + TrainingsActions.RESET, null, { root: true });
              dispatch(UsersPath + '/' + UsersActions.RESET, null, { root: true });
              resolve()
          })
      },
      [AuthActions.REFRESH_DATA]: ({ commit }) => {
        return new Promise((resolve) => {
          axios({url: '/auth', method: 'GET' })
            .then(resp => {
              commit(AuthMutations.SET_DATA, resp.data.data.user);
              commit(AuthMutations.SUCCESS, resp.data.data.token);
              resolve();
            })
          })
      },
      [AuthActions.SWITCH_ROLE]: ({ state, commit }) => {
        return new Promise((resolve) => {
          if (state.role != 'user') {
            commit(AuthMutations.SET_ROLE, 'user')
          } else {
            if ((state.data.roles || []).includes('admin')) {
              commit(AuthMutations.SET_ROLE, 'admin')
            }
          }
          resolve()
        })
      },

      [AuthActions.SET_PASSWORD]: ({ state }, password) => {
          return new Promise((resolve, reject) => {
            const payload = { userId: state.data.id, password: password }
            axios({url: '/auth/', data: payload, method: "POST"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
          })
      }
    },

    mutations: {
      [AuthMutations.REQUEST]: (state) => {
        state.status = 'loading';
        state.data = '';
      },
      [AuthMutations.SUCCESS]: (state, token) => {
        localStorage.setItem('user-token', token) // store the token in localstorage
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        state.token = token;
        state.status = 'success';
      },
      [AuthMutations.SET_DATA]: (state, data) => {
        state.data = data;
      },
      [AuthMutations.SET_ROLE]: (state, role) => {
        state.role = role;
      },
      [AuthMutations.ERROR]: (state) => {
        state.status = 'error';
        state.data = '';
      },
      [AuthMutations.LOGOUT]: (state) => {
        state.status = '';
        state.data = '';
        localStorage.removeItem('user-token')
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization']
        state.token = '';
      }
    }
}

export default authStore;