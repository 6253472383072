import axios from 'axios'
import { ProjectsActions, ProjectsGetters, ProjectsMutations } from './definitions';

const projectStore = {
    namespaced: true,
    state: {
        projects: [],
        projectTrainings: [],
        projectUsers: []
    },

    actions: {
      [ProjectsActions.REFRESH]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              axios({url: '/projects', method: 'GET' })
                .then(resp => {
                  const projects = resp.data.data;
                  commit(ProjectsMutations.REFRESH, projects)
                  resolve(resp)
                })
              .catch(err => {
                reject(err)
              })
            })
      },
      [ProjectsActions.REFRESH_USERS]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              axios({url: '/projectUsers', method: 'GET' })
                .then(resp => {
                  const users = resp.data.data;
                  commit(ProjectsMutations.REFRESH_USERS, users)
                  resolve(resp)
                })
              .catch(err => {
                reject(err)
              })
            })
      },
      [ProjectsActions.REFRESH_TRAININGS]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              axios({url: '/projectTrainings', method: 'GET' })
                .then(resp => {
                  const trainings = resp.data.data;
                  commit(ProjectsMutations.REFRESH_TRAININGS, trainings)
                  resolve(resp)
                })
              .catch(err => {
                reject(err)
              })
            })
      },

      [ProjectsActions.ADD_PROJECT]: ({ dispatch }, project) => {
        return new Promise((resolve, reject) => {
          axios({url: '/projects', data: project, method: 'POST' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.REMOVE_PROJECT]: ({ dispatch }, projectId) => {
        return new Promise((resolve, reject) => {
          axios({url: `/projects/${projectId}`, method: 'DELETE' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.ADD_PROJECT_USER]: ({ dispatch }, projectUser) => {
        return new Promise((resolve, reject) => {
          axios({url: '/projectUsers', data: projectUser, method: 'POST' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH_USERS)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.REMOVE_PROJECT_USER]: ({ dispatch }, projectUserId) => {
        return new Promise((resolve, reject) => {
          axios({url: `/projectUsers/${projectUserId}`, method: 'DELETE' })
            .then(() => {
              dispatch(ProjectsActions.REFRESH_USERS)
              .then(() => resolve())
            })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.ADD_PROJECT_TRAINING]: ({ dispatch }, projectTraining) => {
        return new Promise((resolve, reject) => {
          axios({url: '/projectTrainings', data: projectTraining, method: 'POST' })
          .then(() => {
            dispatch(ProjectsActions.REFRESH_TRAININGS)
            .then(() => resolve())
          })
          .catch(err => {
            reject(err)
          })
        })
      },

      [ProjectsActions.REMOVE_PROJECT_TRAINING]: ({ dispatch }, projectTrainingId) => {
        return new Promise((resolve, reject) => {
          axios({url: `/projectTrainings/${projectTrainingId}`, method: 'DELETE' })
          .then(() => {
            dispatch(ProjectsActions.REFRESH_TRAININGS)
            .then(() => resolve())
          })
          .catch(err => {
            reject(err)
          })
        })
      },
      
      [ProjectsActions.RESET]: ({ commit }) => new Promise(resolve => {
        commit(ProjectsMutations.CLEAR);
        resolve();
      })
    },

    mutations: {
      [ProjectsMutations.REFRESH]: function(state, payload) {
          state.projects = [];
          for (const project of payload) {
            state.projects.push(project)
          }
      },

      
      [ProjectsMutations.REFRESH_USERS]: function(state, payload) {
          state.projectUsers = [];
          for (const projectUser of payload) {
              state.projectUsers.push(projectUser)
          }
      },
      
      [ProjectsMutations.REFRESH_TRAININGS]: function(state, payload) {
        state.projectTrainings = [];
        for (const projectTraining of payload) {
            state.projectTrainings.push(projectTraining)
        }
      },

      [ProjectsMutations.CLEAR]: function(state) {
        state.projects = [];
        state.projectTrainings = [];
        state.projectUsers = [];
      }
    },

    getters: {
      [ProjectsGetters.PROJECTS]: (state) => {
        return state.projects;
      },

      [ProjectsGetters.PROJECTS_BY_USER_ID]: (state, getters) => (userId) => {
        const projectIdsWithDuplicates = getters[ProjectsGetters.PROJECT_IDS_BY_USER_ID](userId);
        const projectIds = [...new Set(projectIdsWithDuplicates)];
        const res = projectIds.map(pi => state.projects.find(p => p.id == pi)).filter(e => e != undefined);
        return res;
      },

      [ProjectsGetters.PROJECT_IDS_BY_USER_ID]: (state) => (userId) => {
        const projectUsers = state.projectUsers.filter(pu => pu.userId == userId);
        return projectUsers.map(pu => pu.projectId);
      },

      [ProjectsGetters.USERS_BY_PROJECT_ID]: (state, getters, rootState) => (projectId) => {
        const projectUsers = state.projectUsers.filter(pu => pu.projectId == projectId);
        return projectUsers.map(pu => {
          let user = { ...rootState.users.users.find(u => u.id === pu.userId) }
          if (!user) {
            return false
          }
          user.projectUserId = pu.id
          user.role = pu.role
          return user
        }).filter(Boolean)
      },
      
      [ProjectsGetters.TRAININGS_BY_PROJECT_ID]: (state, getters, rootState) => (projectId) => {
        const projectTrainings = state.projectTrainings.filter(pt => pt.projectId == projectId);
        return projectTrainings.map(pt => {
          let training = { ...rootState.trainings.trainings.find(t => t.id === pt.trainingId) }
          training.projectTrainingId = pt.id
          return training
        });
      },

      [ProjectsGetters.TRAININGS_BY_PROJECT_TRAINING_ID]: (state, getters, rootState) => (projectTrainingId) => {
        const projectTraining = state.projectTrainings.find(pt => pt.id == projectTrainingId);
        return rootState.trainings.trainings.find(t => t.id === projectTraining.id);
      }
    }
}

export default projectStore;