import axios from 'axios'
import { UsersActions, UsersGetters, UsersMutations } from './definitions'

const userStore = {
    namespaced: true,
    state: {
        users: [],
    },

    actions: {
        [UsersActions.REFRESH]: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios({url: '/users', method: 'GET' })
                  .then(resp => {
                    const users = resp.data.data;
                    commit(UsersMutations.REFRESH, users)
                    resolve(resp)
                  })
                .catch(err => {
                  reject(err)
                })
              })
        },

        [UsersActions.CREATE]: ({ dispatch }, payload) => {
            return new Promise((resolve, reject) => {
                axios({url: '/users', data: payload, method: "POST"})
                    .then(() => {
                        dispatch(UsersActions.REFRESH);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },

        [UsersActions.EDIT]: (_, payload) => {
            return new Promise((resolve, reject) => {
                payload.token = null;
                axios({url: '/users/' + payload.id, data: payload, method: "PUT"})
                    .then(() => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
      
        [UsersActions.RESET]: ({ commit }) => new Promise(resolve => {
          commit(UsersMutations.CLEAR);
          resolve();
        }),
      
        [UsersActions.RESET_PIN]: (_, userId) => new Promise((resolve, reject) => {
            axios({url: '/auth/reset', data: { id: userId }, method: "POST"})
            .then(() =>  resolve())
            .catch(err => {
                reject(err)
            })
        }),

        [UsersActions.REMOVE_USER]: ({ dispatch }, userId) => new Promise((resolve, reject) => {
            axios({url: `/users/${userId}`, method: 'DELETE' })
            .then(() => {
                dispatch(UsersActions.REFRESH)
                .then(() => resolve())
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    getters: {
        [UsersGetters.USERS]: function(state) {
            return state.users
        }
    },

    mutations: {
        [UsersMutations.ADD]: function(state, payload) {
            const user = {
                id: payload.id,
                name: payload.name,
                firstName: payload.firstName,
                phone: payload.phone,
                email: payload.email,
                registrationStatus: payload.registrationStatus
            };
            state.users.push(user)
        },

        [UsersMutations.REFRESH]: function(state, payload) {
            state.users = [];
            for (const user of payload) {
                state.users.push(user)
            }
        },

        [UsersMutations.CLEAR]: function(state) {
          state.users = [];
        }
    }
}

export default userStore;