<template>
  <div class="home">
    <v-card-title v-if="!isAutenticated">Zugriff verweigert</v-card-title>
    <v-card-title v-else>Übersicht für {{ data.firstName }} {{ data.name }}</v-card-title>
    <v-form class="ml-4 mr-4">
      <v-row>
        <v-col md="3" cols="6">
          <v-text-field
            :label="canBeAdmin ? 'Administrator' : 'Token'"
            :disabled="canBeAdmin"
            readonly
            v-model="data.token"
          ></v-text-field>
        </v-col>
        <v-col md="3" cols="6">
          <v-text-field
            :label="canBeAdmin ? 'Passwort' : 'Pin'"
            :disabled="canBeAdmin"
            v-model="pin"
            :prepend-icon="showPin ? 'mdi-close' : ''"
            @click:prepend="showPin = false; pin = pinPlaceholder"
            :append-icon="canBeAdmin ? '' : (showPin ? 'mdi-send' : 'mdi-pencil')"
            @click:append="clickShowPin"
          >
          </v-text-field>
        </v-col>
        <v-col md="3" cols="6">
          <v-text-field
            label="E-Mail"
            :disabled="canBeAdmin"
            v-model="data.email"
            :outlined="editEmail"
            :readonly="!editEmail"
            :prepend-icon="editEmail ? 'mdi-close' : ''"
            @click:prepend="editEmail = false"
            :append-icon="editEmail ? 'mdi-send' : 'mdi-pencil'"
            @click:append="editEmail = !editEmail; saveUser()"
          >
          </v-text-field>
        </v-col>
        <v-col md="3" cols="6">
          <v-text-field
            label="Telefonnummer"
            v-model="data.phone"
            :outlined="editPhone"
            :readonly="!editPhone"
            :prepend-icon="editPhone ? 'mdi-close' : ''"
            @click:prepend="editPhone = false"
            :append-icon="editPhone ? 'mdi-send' : 'mdi-pencil'"
            @click:append="editPhone = !editPhone; saveUser()"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col md="6" cols="12">
        <v-card-title>Projekte</v-card-title>
        <v-data-table
          :headers="projectsHeaders"
          :items="projects"
          :search="projectsSearch"
          mobile-breakpoint="0"
          sort-by="name"
          @click:row="clickProject"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="projectsSearch"
                prepend-inner-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>

      <v-col md="6" cols="12">
        <v-card-title>Schulungen</v-card-title>
        <v-data-table
          :headers="trainingsHeaders"
          :items="trainings"
          :search="trainingsSearch"
          mobile-breakpoint="0"
          sort-by="name"
          @click:row="clickTraining"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="trainingsSearch"
                prepend-inner-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { AuthActions, AuthGetters, AuthPath, ProjectsActions, ProjectsGetters, ProjectsPath, TrainingsActions, TrainingsGetters, TrainingsPath, UsersActions, UsersPath } from '../store/definitions'
// @ is an alias to /src

export default {
  name: 'HomeUser',

  mounted: function() {
    this[ProjectsActions.REFRESH_USERS]();
    this[ProjectsActions.REFRESH_TRAININGS]();
    this[ProjectsActions.REFRESH]();
    this[TrainingsActions.REFRESH]();
    this.pin = this.pinPlaceholder
  },

  data: () => {
    return {
      showPin: false,
      editEmail: false,
      editPhone: false,
      pin: '',
      pinPlaceholder: '****************************************',
      trainingsHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Status', align: 'end', sortable: false, value: 'placeholder' },
      ],
      projectsHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Status', align: 'end', sortable: false, value: 'status' },
      ],
      trainingsSearch: '',
      projectsSearch: '',
    }
  },

  computed: {
    trainings: function() {
      if (this.data == undefined) {
        return [];
      }
      return this.trainingsByUserId(this.data.id);
    },
    
    projects: function() {
      if (this.data == undefined) {
        return [];
      }
      return this.projectsByUserId(this.data.id);
    },

    ...mapState(AuthPath, [
      'data'
    ]),

    ...mapGetters(AuthPath, {
      isAutenticated: AuthGetters.IS_AUTHENTICATED,
      canBeAdmin: AuthGetters.CAN_BE_ADMIN
    }),

    ...mapGetters(TrainingsPath, { trainingsByUserId: TrainingsGetters.TRAININGS_BY_USER_ID }),

    ...mapGetters(ProjectsPath, { projectsByUserId: ProjectsGetters.PROJECTS_BY_USER_ID })
  },

  methods: {
    clickProject: function(project) {
      this.$router.push(`/projects/${project.id}`)
    },
    
    clickTraining: function(training) {
      this.$router.push(`/trainings/${training.id}`)
    },

    clickShowPin: function() {
      if (this.showPin) {
        this[AuthActions.SET_PASSWORD](this.pin)
        this.pin = this.pinPlaceholder
        this.showPin = false
      } else {
        this.pin = ''
        this.showPin = true
      }
    },

    saveUser: function() {
      const user = {
        id: this.data.id,
        phone: this.data.phone,
        email: this.data.email
      }
      this[UsersActions.EDIT](user);
    },

    ...mapActions(AuthPath, [ AuthActions.SET_PASSWORD ]),

    ...mapActions(UsersPath, [ UsersActions.EDIT ]),

    ...mapActions(TrainingsPath, [
      TrainingsActions.REFRESH,
      TrainingsActions.REFRESH_QUESTIONS, 
      TrainingsActions.REFRESH_OPTIONS
    ]),

    ...mapActions(ProjectsPath, [
      ProjectsActions.REFRESH,
      ProjectsActions.REFRESH_USERS,
      ProjectsActions.REFRESH_TRAININGS
    ])
  }
}
</script>
