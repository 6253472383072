import axios from 'axios'
import { TrainingsActions, TrainingsMutations, TrainingsGetters, ProjectsPath, ProjectsGetters } from './definitions';

const trainingStore = {
  namespaced: true,
  state: {
    trainings: [],
    trainingQuestions: [],
    trainingQuestionOptions: [],
    trainingSubmissions: []
  },

  actions: {
    [TrainingsActions.REFRESH]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios({url: '/trainings', method: 'GET' })
              .then(resp => {
                const trainings = resp.data.data;
                commit(TrainingsMutations.REFRESH, trainings)
                resolve(resp)
              })
            .catch(err => {
              reject(err)
            })
          })
    },
    
    [TrainingsActions.REFRESH_QUESTIONS]: ({ commit }) => {
      return new Promise((resolve, reject) => {
          axios({url: '/trainingQuestions', method: 'GET' })
            .then(resp => {
              const trainingQuestions = resp.data.data;
              commit(TrainingsMutations.REFRESH_QUESTIONS, trainingQuestions)
              resolve(resp)
            })
          .catch(err => {
            reject(err)
          })
        })
    },
    
    [TrainingsActions.REFRESH_OPTIONS]: ({ commit }) => {
      return new Promise((resolve, reject) => {
          axios({url: '/trainingQuestionOptions', method: 'GET' })
            .then(resp => {
              const trainingQuestionOptions = resp.data.data;
              commit(TrainingsMutations.REFRESH_OPTIONS, trainingQuestionOptions)
              resolve(resp)
            })
          .catch(err => {
            reject(err)
          })
        })
    },
    
    [TrainingsActions.REFRESH_SUBMISSIONS]: ({ commit }) => {
      return new Promise((resolve, reject) => {
          axios({url: '/trainingSubmissions', method: 'GET' })
            .then(resp => {
              const trainingSubmissions = resp.data.data;
              commit(TrainingsMutations.REFRESH_SUBMISSIONS, trainingSubmissions)
              resolve(resp)
            })
          .catch(err => {
            reject(err)
          })
        })
    },
      
    [TrainingsActions.RESET]: ({ commit }) => new Promise(resolve => {
      commit(TrainingsMutations.CLEAR);
      resolve();
    }),

    [TrainingsActions.ADD_TRAINING]: ({ dispatch }, training) => {
      return new Promise((resolve, reject) => {
        axios({url: '/trainings', data: training, method: 'POST' })
        .then((resp) => {
          dispatch(TrainingsActions.REFRESH)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.EDIT_TRAINING]: (_, payload) => {
        return new Promise((resolve, reject) => {
            payload.token = null;
            axios({url: '/trainings/' + payload.id, data: payload, method: "PUT"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    [TrainingsActions.REMOVE_TRAINING]: ({ dispatch }, trainingId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/trainings/${trainingId}`, method: 'DELETE' })
        .then(() => {
          dispatch(TrainingsActions.REFRESH)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.DUPLICATE_TRAINING]: ({ dispatch, getters }, trainingId) => new Promise(() => {
      dispatch(TrainingsActions.REFRESH)
      .then(() => dispatch(TrainingsActions.REFRESH_QUESTIONS)
      .then(() => dispatch(TrainingsActions.REFRESH_OPTIONS)
      .then(() => {
        const training = { ...getters[TrainingsGetters.TRAINING_BY_ID](trainingId) }
        training.name = `${training.name} (Kopie)`
        const questions = getters[TrainingsGetters.QUESTIONS_BY_TRAINING_ID](trainingId)
        dispatch(TrainingsActions.ADD_TRAINING, training)

        .then((duplicatedTraining) => {
          for (const { ...question } of questions) {
            question.trainingId = duplicatedTraining.id
            const options = getters[TrainingsGetters.OPTIONS_BY_QUESTION_ID](question.id)
            dispatch(TrainingsActions.ADD_QUESTION, question)

            .then((duplicatedQuestion) => {
              for (const { ...option} of options) {
                option.trainingQuestionId = duplicatedQuestion.id
                dispatch(TrainingsActions.ADD_OPTION, option)
              }
            })
          }
        })
      })))
    }),

    [TrainingsActions.ADD_QUESTION]: ({ dispatch }, question) => {
      return new Promise((resolve, reject) => {
        axios({url: '/trainingQuestions', data: question, method: 'POST' })
        .then((resp) => {
          dispatch(TrainingsActions.REFRESH_QUESTIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.ADD_OPTION]: ({ dispatch }, option) => {
      return new Promise((resolve, reject) => {
        axios({url: '/trainingQuestionOptions', data: option, method: 'POST' })
        .then((resp) => {
          dispatch(TrainingsActions.REFRESH_OPTIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.EDIT_QUESTION]: (_, payload) => {
        return new Promise((resolve, reject) => {
            payload.token = null;
            axios({url: '/trainingQuestions/' + payload.id, data: payload, method: "PUT"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    [TrainingsActions.REMOVE_QUESTION]: ({ dispatch }, questionId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/trainingQuestions/${questionId}`, method: 'DELETE' })
        .then(() => {
          dispatch(TrainingsActions.REFRESH_QUESTIONS)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.EDIT_OPTION]: (_, payload) => {
        return new Promise((resolve, reject) => {
            payload.token = null;
            axios({url: '/trainingQuestionOptions/' + payload.id, data: payload, method: "PUT"})
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                })
        })
    },

    [TrainingsActions.REMOVE_OPTION]: ({ dispatch }, optionId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/trainingQuestionOptions/${optionId}`, method: 'DELETE' })
        .then(() => {
          dispatch(TrainingsActions.REFRESH_OPTIONS)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.ADD_SUBMISSION]: ({ dispatch }, submission) => {
      return new Promise((resolve, reject) => {
        axios({url: '/trainingSubmissions', data: submission, method: 'POST' })
        .then((resp) => {
          dispatch(TrainingsActions.REFRESH_SUBMISSIONS)
          .then(() => resolve(resp.data.data))
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.EDIT_SUBMISSION]: (_, submission) => {
      return new Promise((resolve, reject) => {
        axios({url: '/trainingSubmissions/' + submission.id, data: submission, method: "PUT"})
            .then(() => {
                resolve();
            })
            .catch(err => {
                reject(err);
            })
      })
    },

    [TrainingsActions.REMOVE_SUBMISSION]: ({ dispatch }, submissionId) => {
      return new Promise((resolve, reject) => {
        axios({url: `/trainingSubmissions/${submissionId}`, method: 'DELETE' })
        .then(() => {
          dispatch(TrainingsActions.REFRESH_SUBMISSIONS)
          .then(() => resolve())
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    [TrainingsActions.ADD_SUBMISSION_OPTION]: (_, option) => {
      return new Promise((resolve, reject) => {
        axios({url: '/trainingSubmissionOptions', data: option, method: 'POST' })
        .then((resp) => resolve(resp.data.data))
        .catch(err => {
          reject(err)
        })
      })
    },
  },

  mutations: {
    [TrainingsMutations.REFRESH]: function(state, payload) {
      state.trainings = [];
      for (const training of payload) {
          state.trainings.push(training)
      }
    },
      
    [TrainingsMutations.REFRESH_QUESTIONS]: function(state, payload) {
      state.trainingQuestions = [];
      for (const trainingQuestion of payload) {
          state.trainingQuestions.push(trainingQuestion)
      }
    },

    [TrainingsMutations.REFRESH_OPTIONS]: function(state, payload) {
      state.trainingQuestionOptions = [];
      for (const trainingQuestionOption of payload) {
          state.trainingQuestionOptions.push(trainingQuestionOption)
      }
    },

    [TrainingsMutations.REFRESH_SUBMISSIONS]: function(state, payload) {
      state.trainingSubmissions = [];
      for (const trainingSubmission of payload) {
          state.trainingSubmissions.push(trainingSubmission)
      }
    },

    [TrainingsMutations.CLEAR]: function(state) {
      state.trainings = [];
      state.trainingQuestions = [];
      state.trainingQuestionOptions = [];
    }
  },

  getters: {
    [TrainingsGetters.TRAINING_BY_ID]: (state) => (trainingId) =>
      state.trainings.find(t => t.id == trainingId),

    [TrainingsGetters.QUESTIONS_BY_TRAINING_ID]: (state) => (trainingId) =>
      state.trainingQuestions.filter(tq => tq.trainingId == trainingId),
      
    [TrainingsGetters.OPTIONS_BY_QUESTION_ID]: (state) => (questionId) =>
      state.trainingQuestionOptions.filter(tqo => tqo.trainingQuestionId == questionId),
    
    [TrainingsGetters.TRAININGS_BY_USER_ID]: (state, getters, rootState, rootGetters) => (userId) => {
      let userTrainings = [];
      const projectIdsWithDuplicates = rootGetters[ProjectsPath + '/' + ProjectsGetters.PROJECT_IDS_BY_USER_ID](userId);
      const projectIds = [...new Set(projectIdsWithDuplicates)]
      projectIds.forEach(projectId => {
        if (projectId !== undefined) {
          const trainings = rootGetters[ProjectsPath + '/' + ProjectsGetters.TRAININGS_BY_PROJECT_ID](projectId);
          trainings.forEach(training => {
            if (training !== undefined && training.id !== undefined) {
              if (!userTrainings.find(t => t.id == training.id)) {
                userTrainings.push(training);
              }
            }
          });
        }
      });
      return userTrainings;
    },
    
    [TrainingsGetters.SUBMISSIONS_BY_TRAINING_ID]: (state) => (trainingId) => {
      return state.trainingSubmissions.filter(s => s.trainingId == trainingId)
    }
  }
}

export default trainingStore;