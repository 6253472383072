export const Actions = {
    NOTIFICATION_SHOW_ERROR: 'NOTIFICATION_SHOW_ERROR',
    NOTIFICATION_SHOW_INFO: 'NOTIFICATION_SHOW_INFO'
}
export const Mutations = {
    NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
    NOTIFICATION_HIDE: 'NOTIFICATION_HIDE'
}
export const Getters = {
    NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
    NOTIFICATION_TYPE: 'NOTIFICATION_TYPE',
    NOTIFICATION_TEXT: 'NOTIFICATION_TEXT'
}
export const NOTIFICATION_TYPE = {
    ERROR: 'ERROR',
    INFO: 'INFO'
}

export const AuthPath = 'auth'
export const AuthActions = {
    REFRESH: 'AUTH_REFRESH',
    REQUEST: 'AUTH_REQUEST',
    REGISTER: 'AUTH_REGISTER',
    LOGOUT: 'AUTH_LOGOUT',
    REFRESH_DATA: 'AUTH_REFRESH_DATA',
    SWITCH_ROLE: 'AUTH_SWITCH_ROLE',
    SET_PASSWORD: 'AUTH_SET_PASSWORD'
}
export const AuthMutations = {
    REQUEST: 'AUTH_REQUEST',
    SUCCESS: 'AUTH_SUCCESS',
    SET_DATA: 'AUTH_SET_DATA',
    SET_ROLE: 'AUTH_SET_ROLE',
    ERROR: 'AUTH_ERROR',
    LOGOUT: 'AUTH_LOGOUT'
}
export const AuthGetters = {
    IS_AUTHENTICATED: 'AUTH_IS_AUTHENTICATED',
    STATUS: 'AUTH_STATUS',
    CAN_BE_ADMIN: 'AUTH_CAN_BE_ADMIN',
    IS_ADMIN: 'AUTH_IS_ADMIN',
    IS_USER: 'AUTH_IS_USER',
    USERID: 'AUTH_USERID'
}

export const UsersPath = 'users'
export const UsersActions = {
    REFRESH: 'USERS_REFRESH',
    CREATE: 'USERS_CREATE',
    EDIT: 'USERS_EDIT',
    RESET: 'USERS_RESET',
    REMOVE_USER: 'USERS_REMOVE_USER',
    RESET_PIN: 'USERS_RESET_PIN'
}
export const UsersMutations = {
    REFRESH: 'USERS_REFRESH',
    ADD: 'USERS_ADD',
    CLEAR: 'USERS_CLEAR'
}
export const UsersGetters = {
    USERS: 'USERS_USERS'
}

export const ProjectsPath = 'projects'
export const ProjectsActions = {
    REFRESH: 'PROJECTS_REFRESH',
    REFRESH_USERS: 'PROJRECTS_REFRESH_USERS',
    REFRESH_TRAININGS: 'PROJECTS_REFRESH_TRAININGS',
    RESET: 'PROJECTS_RESET',
    ADD_PROJECT: 'PROJECTS_ADD_PROJECT',
    REMOVE_PROJECT: 'PROJECTS_REMOVE_PROJECT',
    ADD_PROJECT_USER: 'PROJECTS_ADD_PROJECT_USER',
    REMOVE_PROJECT_USER: 'PROJECTS_REMOVE_PROJECT_USER',
    ADD_PROJECT_TRAINING: 'PROJECTS_ADD_PROJECT_TRAINING',
    REMOVE_PROJECT_TRAINING: 'PROJECTS_REMOVE_PROJECT_TRAINING'
}
export const ProjectsMutations = {
    REFRESH: 'PROJECTS_REFRESH',
    REFRESH_USERS: 'PROJRECTS_REFRESH_USERS',
    REFRESH_TRAININGS: 'PROJECTS_REFRESH_TRAININGS',
    CLEAR: 'PROJECTS_CLEAR'
}
export const ProjectsGetters = {
    PROJECTS: 'PROJECTS_PROJECTS',
    PROJECTS_BY_USER_ID: 'PROJECTS_PROJECTS_BY_USER_ID',
    PROJECT_IDS_BY_USER_ID: 'PROJECTS_PROJECT_IDS_BY_USER_ID',
    USERS_BY_PROJECT_ID: 'PROJECTS_USERS_BY_PROJECT_ID',
    TRAININGS_BY_PROJECT_ID: 'PROJECTS_TRAININGS_BY_PROJECT_ID',
    TRAININGS_BY_PROJECT_TRAINING_ID: 'PROJECTS_TRAININGS_BY_PROJECT_TRAINING_ID'
}

export const TrainingsPath = 'trainings'
export const TrainingsActions = {
    REFRESH: 'TRAININGS_REFRESH',
    REFRESH_QUESTIONS: 'TRAININGS_REFRESH_QUESTIONS',
    REFRESH_OPTIONS: 'TRAININGS_REFRESH_OPTIONS',
    REFRESH_SUBMISSIONS: 'TRAININGS_REFRESH_SUBMISSIONS',
    RESET: 'TRAININGS_RESET',
    DUPLICATE_TRAINING: 'TRAININGS_DUPLICATE_TRAINING',
    ADD_TRAINING: 'TRAININGS_ADD_TRAINING',
    ADD_QUESTION: 'TRAININGS_ADD_QUESTION',
    ADD_OPTION: 'TRAININGS_ADD_OPTION',
    ADD_SUBMISSION: 'TRAININGS_ADD_SUBMISSION',
    ADD_SUBMISSION_OPTION: 'TRAININGS_ADD_SUBMISSION_OPTION',
    EDIT_TRAINING: 'TRAININGS_EDIT_TRAINING',
    EDIT_QUESTION: 'TRAININGS_EDIT_QUESTION',
    EDIT_OPTION: 'TRAININGS_EDIT_OPTION',
    EDIT_SUBMISSION: 'TRAININGS_EDIT_SUBMISSION',
    REMOVE_TRAINING: 'TRAININGS_REMOVE_TRAINING',
    REMOVE_QUESTION: 'TRAININGS_REMOVE_QUESTION',
    REMOVE_OPTION: 'TRAININGS_REMOVE_OPTION',
    REMOVE_SUBMISSION: 'TRAININGS_REMOVE_SUBMISSION'
}
export const TrainingsMutations = {
    REFRESH: 'TRAININGS_REFRESH',
    REFRESH_QUESTIONS: 'TRAININGS_REFRESH_QUESTIONS',
    REFRESH_OPTIONS: 'TRAININGS_REFRESH_OPTIONS',
    REFRESH_SUBMISSIONS: 'TRAININGS_REFRESH_SUBMISSIONS',
    CLEAR: 'TRAININGS_CLEAR'
}
export const TrainingsGetters = {
    TRAINING_BY_ID: 'TRAININGS_TRAINING_BY_ID',
    QUESTIONS_BY_TRAINING_ID: 'TRAININGS_QUESTIONS_BY_TRAINING_ID',
    OPTIONS_BY_QUESTION_ID: 'TRAININGS_OPTIONS_BY_QUESTION_ID',
    TRAININGS_BY_USER_ID: 'TRAININGS_TRAININGS_BY_USER_ID',
    SUBMISSIONS_BY_TRAINING_ID: 'TRAININGS_SUBMISSIONS_BY_TRAINING_ID'
}